import { Component } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ConfigurationService } from '../services/configuration.service';
import { ActivatedRoute, Router } from '@angular/router';

const CGV_ACCEPTED = 'cgv_accepted';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  cgvAccepted = false;
  cgvShowed = false;
  cgvChecked = false;

  constructor(public configuration: ConfigurationService, private route: ActivatedRoute, private router: Router) {
    this.retrieveCGVAccepted();
  }

  private async retrieveCGVAccepted() {
    const cgvAccepted = await Storage.get({ key: CGV_ACCEPTED });
    if (cgvAccepted && cgvAccepted.value == '1') {
      this.cgvAccepted = true;
    } else {
      this.cgvAccepted = false;
    }
  }

  ngOnInit() {
    // Retrieve URL parameter for event UUID
    this.route.queryParams
      .subscribe(params => {
        this.configuration.launchConfigurationProcess(params['event']);
      }
      );
  }

  showCGV() {
    this.cgvShowed = !this.cgvShowed;
  }

  checkedCGV() {
    this.cgvChecked = !this.cgvChecked;
  }

  showCameraPreview() {
    Storage.set({ key: CGV_ACCEPTED, value: '1' });
    this.router.navigate(['/capture']);
  }
  
  showImageCropper() {
    this.router.navigate(['/image-cropper']);
  }
}

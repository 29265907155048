import { Injectable } from '@angular/core';
import { CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions } from '@capacitor-community/camera-preview';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  mediaRecorder: any;
  position: string = 'front';

  currentImage: string;

  constructor(public configuration: ConfigurationService) { }

  async takePicture(): Promise<{ value: string }> {
    const cameraPreviewPictureOptions: CameraPreviewPictureOptions = {
      quality: 100,
      height: 1920,
      width: 1080
    }
    return await CameraPreview.capture(cameraPreviewPictureOptions);
  }

  cropWithMask(): Promise<string> {
    return new Promise((resolve) => {
      var img = new Image();
      img.onload = () => {
        var mask = new Image();
        mask.src = 'data:image/jpeg;base64,' + this.configuration.current_config.event_mask_b64;

        const qualityMax = 1920;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        // let landscapeAdjustHeight = 1;
        // let landscapeAdjustWidth = 1;

        // // Ajout du masque
        // var maskIsPortrait = true;
        // var capteurIsPortrait = true;
        var maskRatio = mask.width/mask.height;
        
        // if(mask.width > mask.height){
        //   maskIsPortrait = false;
        // }
        // if(screen.availWidth > screen.availHeight){
        //   capteurIsPortrait = false;
        // }

        // let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        // if(isSafari){
        //   var mql = window.matchMedia("(orientation: portrait)");
        //   if(!mql.matches) {  
        //     capteurIsPortrait = false;
        //   }
        // }
        //Camera quality
        var heightCamera = img.height;
        var widthCamera = img.width;
        //Quality max
        // var qualityFull = Math.min(Math.max(heightCamera, widthCamera), qualityMax);
        // //Taille de l'image finale (ne peut être plus grande que les pixels du capteur)
        // var heightFinale = Math.round(Math.min(qualityFull, qualityFull/maskRatio));
        // var widthFinale = Math.round(Math.min(qualityFull, qualityFull/(1/maskRatio)));
        // //Taille de l'image preview
        // var heightPreview = document.getElementById("cardPreview").clientHeight;
        // var widthPreview = document.getElementById("cardPreview").clientWidth;
        // //Camera capture (A part could be hidden)
        // var heightCapture = heightPreview;
        // var widthCapture = Math.round(heightPreview/heightCamera*widthCamera);
        // //Zoom (=ratio) effectué
        // var heightZoom = heightCapture / heightCamera;
        // var widthZoom = widthCapture / widthCamera;

        //Image finale
        // canvas.height = heightFinale;
        // canvas.width = widthFinale;
        canvas.height = mask.height;
        canvas.width = mask.width;
        // var widthZoomJoker = 1;
        
        // if(!maskIsPortrait){
        //     //Calcul du réajustement pour passer de portrait à paysage dépend des ratios du mask et de la vidéo
        //   if(!capteurIsPortrait){
        //     landscapeAdjustWidth = (mask.width * heightCapture) / (mask.height * widthCapture);
        //     landscapeAdjustHeight = 1-(landscapeAdjustWidth-1);
        //   }else{
        //     widthZoomJoker = 2;
        //   }
        // }
        //Calcul du décalage x, y sur l'image a afficher
        // var sy = Math.max(0, Math.round((heightCapture - heightPreview) / 2 / heightZoom));
        // var sx = Math.max(0, Math.round((widthCapture - widthPreview) / 2 / widthZoom));
        
        let newWidth = heightCamera*maskRatio;
        let newHeight =  widthCamera/maskRatio;
        let clipHorizontale = 0;
        let clipVertical = 0;
        if(mask.width < mask.height){
          clipHorizontale = (widthCamera - newWidth)/2
          ctx.drawImage(img, clipHorizontale, 0, newWidth, heightCamera, 0, 0, mask.width, mask.height);

        }else{
          clipVertical =(heightCamera - newHeight)/2
          // alert(clipVertical)
          ctx.drawImage(img, 0, 0, widthCamera, newHeight, 0, 0, mask.width, mask.height);
        }

        // video.style = "clip-path: inset("+clipVertical+"px "+clipHorizontale+"px "+clipVertical+"px "+clipHorizontale+"px round 10px); width:"+videoWidth+"px; height:"+videoHeight+"px; "
        // let a = document.querySelector('#cameraPreview')
        // ctx.drawImage(img, sx, sy, widthPreview/widthZoom/widthZoomJoker, heightPreview/heightZoom*landscapeAdjustHeight/widthZoomJoker, 0, 0, widthFinale*landscapeAdjustWidth, heightFinale);
        // ctx.drawImage(img, clipHorizontale, 0, newWidth, heightCamera, 0, 0, mask.width, mask.height);
        // Ajout du masque
        ctx.drawImage(mask, 0, 0, mask.width, mask.height, 0, 0, mask.width, mask.height);

        resolve(canvas.toDataURL("image/png"));
      };
      if (this.currentImage) {
        img.src = 'data:image/jpeg;base64,' + this.currentImage;
      }
    });
  }

  async recordVideo() {
    // Create a stream of video capturing
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    });
    return new Promise<string>((resolve) => {
      var options = { mimeType: 'video/webm' };
      this.mediaRecorder = new MediaRecorder(stream, options);
      // Tmp storing for video
      let chunks = [];
      // Store the video on stop
      this.mediaRecorder.onstop = async () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        resolve(await this.convertBlobToBase64(blob) as string);
      }
      // Store chunks of recorded video
      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          chunks.push(event.data)
        }
      }
      // Start recording with chunks of data
      this.mediaRecorder.start(100);
    });
  }

  private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  stopRecord() {
    this.mediaRecorder.stop();
    this.mediaRecorder = null;
  }

  async flipCamera() {
    // Flip camera function is not implemented yet. So Stop camera, change position and start again
    await this.stopCamera();
    this.position = this.position == 'front' ? 'rear' : 'front';
  }

  startCamera() {
    return new Promise((successCallback, failureCallback) => {
      setTimeout(() => {
        let landscape = (this.configuration.current_config.landscape) ? " video-mask-landscape " : " video-mask-portrait ";
        const cameraPreviewOptions: CameraPreviewOptions = {
          position: this.position,
          parent: 'cameraPreview',
          className: 'cameraPreviewVideo '+ landscape,
          rotateWhenOrientationChanged: true,
          enableHighResolution: true,
          disableAudio: true,
          enableOpacity: true,
          enableZoom: true,
        };
        CameraPreview.start(cameraPreviewOptions).then(()=>{
          successCallback("");
        });
      }, 100);
    });
  }

//   oldStartCamera(){
//     //Prevent Error: Uncaught (in promise) TypeError: parent is null
//     //Le start() est fait trop tot
//     if(document.getElementById("cameraPreview") == null) {
//       setTimeout(() => {this.startCamera()}, 200);
//   } else {
//     let landscape = (this.configuration.current_config.landscape) ? " video-mask-landscape " : " video-mask-portrait ";
//     const cameraPreviewOptions: CameraPreviewOptions = {
//       position: this.position,
//       parent: 'cameraPreview',
//       className: 'cameraPreviewVideo '+ landscape,
//       rotateWhenOrientationChanged: true,
//       enableHighResolution: true,
//       disableAudio: true,
//       enableOpacity: true,
//       enableZoom: true,
//     };
//     CameraPreview.start(cameraPreviewOptions);
//   }
// }

  async stopCamera() {
    await CameraPreview.stop();
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from "@ionic/angular";
import { ConfigurationService } from '../services/configuration.service';
import { NotificationComponent } from "../notification/notification.component";
import { CameraService } from '../services/camera.service';
import { interval, Subscription, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Properties from '../../assets/properties.json';
import { ToastController } from '@ionic/angular';
import { MediaForHotspot } from '../media-for-hotspot.model';
import { Email } from '../email.model';
import * as uuid from 'uuid';
import { MediaForServer } from '../media-for-server.model';
import { Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common'

@Component({
  selector: 'app-preview',
  templateUrl: './preview.page.html',
  styleUrls: ['./preview.page.scss'],
})
export class PreviewPage implements OnInit {

  headers: HttpHeaders;
  printStatusSubcription: Subscription;
  printing = false;
  countRetrievePrintStatus = 0;

  currentImage: any;
  currentImage_uid: string;

  hotspots = [];

  constructor(private popover: PopoverController,
      public configuration: ConfigurationService,
      public cameraService: CameraService, 
      private http: HttpClient,
      public toastController: ToastController, 
      private router: Router,
      private location: Location) 
    {
    //Define HTTP Header with CORS possibilities
    this.headers = new HttpHeaders();
    this.setHeaders();
    this.retrieveImageCroppedWithMask();
    this.currentImage_uid = uuid.v4();

    //Permet de gérer l'appelle depuis /capture si /preview était déjà chargé
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.retrieveImageCroppedWithMask();
      }
    });
  }

  ngOnInit() {
    if (this.cameraService.currentImage == null) {
      this.router.navigate(['/capture']);
    }
  }

  setHeaders() {
    Properties.headers.forEach(element => {
      this.headers = this.headers.set(element['key'], element['value']);
      console.log(element['key']);
    });
  }

  async retrieveImageCroppedWithMask() {
    await this.cameraService.cropWithMask().then(img => {
      this.currentImage = img;
    });
  }

  async showNotification(html: string, iconPath: string) {
    const pop = await this.popover.create({
      component: NotificationComponent,
      cssClass: 'notifications',
      translucent: true,
      componentProps: {
        "contentHtml": '<strong>' + html + '</strong>',
        "iconPath": iconPath,
      }
    });
    return await pop.present();
  }

  private getCurrentImage_b64() {
    if (this.currentImage != null) {
      return this.currentImage.replace('data:image/png;base64,', '');
    } else {
      return "";
    }
  }

  selectHotspot() {
    this.hotspots = this.configuration.current_config.hotspots;
    document.querySelector<HTMLElement>('.preview_action').style.display = 'none';
    document.querySelector<HTMLElement>('.preview_print').style.display = 'flex';
  }

  enterEmail() {
    document.querySelector<HTMLElement>('.preview_action').style.display = 'none';
    document.querySelector<HTMLElement>('.preview_email').style.display = 'flex';
  }

  async printToHotspot() {
    const mediaObject = new MediaForHotspot(this.getCurrentImage_b64(), this.currentImage_uid, this.configuration.current_config.event_id, 'photo');
    this.call_print(this.configuration.getHostpostPrintingURL() + this.currentImage_uid + "/from/" + this.configuration.terminal_id, JSON.stringify(mediaObject));
  }

  async printToServer() {
    if (document.querySelector<HTMLInputElement>('input[name=print]:checked') != null) {
      let hotspot_domain = document.querySelector<HTMLInputElement>('input[name=print]:checked').value;
      // let hotspotChosen = document.querySelector('input[name=print][checked]')
      // hotspot_domain = null;
      const params = {
        'source': this.configuration.terminal_id,
        'type': 'photo',
        'event_id': this.configuration.current_config.event_id,
        'file_name': this.currentImage_uid,
        'print_to': hotspot_domain
      };
      const mediaObject = new MediaForServer(this.getCurrentImage_b64(), params);

      this.call_print(this.configuration.getHostpostPrintingURL(), JSON.stringify(mediaObject));
    } else {
      this.show_snackbar("Merci de sélectionner une imprimante");
    }
  }

  call_print(url: string, content: string) {
    // TODO : Mettre un flag permettant de griser le bouton Imprimer le temps de l'impression (éviter le flood)
    if (this.printAuthorisation(this.configuration.terminal_id, this.configuration.current_config.print_time_limit, this.configuration.current_config.print_number_limit)) {
      this.printing = true;
      this.http.post(url, content, { 'headers': this.headers }).subscribe((success) => {
        // Gestion du local storage pour compter le nombre de photos imprimés
        let photosStored = JSON.parse(localStorage.getItem('photos' + this.configuration.terminal_id));
        (photosStored != null && photosStored.length > 0)?"":photosStored=[];
        photosStored.push(Date.now().toString());
        localStorage.setItem('photos' + this.configuration.terminal_id, JSON.stringify(photosStored));
        
        this.showNotification("<strong>Votre impression est prête</strong>", "/assets/svg/download.svg");
        document.querySelector<HTMLElement>('.preview_action').style.display = 'block';
        document.querySelector<HTMLElement>('.preview_print').style.display = 'none';
        this.printing = false;
      }, (error) => {
        console.log(error);
        var alert_message = "Votre demande d'impression a échouée";
        if(error.error.printer_status == "503"){
          alert_message += ". L'imprimante rencontre un problème."
        }
        this.show_snackbar(alert_message);
        this.printing = false;
      });
    }else {
      var alert_message = "Vous avez atteint le maximum d'impression possible pour le moment";
      this.show_snackbar(alert_message);
      this.printing = false;
    }
  }

  private show_snackbar(message: string) {
    const toast = this.toastController.create({
      header: 'Information :',
      message: message,
      position: 'bottom',
      animated: true,
      color: "primary",
      translucent: false,
      duration: 3000,
      buttons: [
        {
          text: 'X',
          role: 'cancel'
        }
      ]
    }).then((toast) => toast.present());
  }


  // async retrievePrintStatus(print_response) {
  //   switch (print_response) {
  //     case '200':
  //       this.showNotification("Votre impression est prête", "/assets/svg/print-outline.svg");
  //       break;
  //     case '503':
  //       this.showNotification("Votre demande d'impression a échouée. L'imprimante rencontre un problème", "/assets/svg/print-outline.svg");
  //       break;
  //     case '500':
  //       this.showNotification("Votre demande d'impression a échouée", "/assets/svg/print-outline.svg");
  //       break;
  //     default:
  //       this.showNotification("Votre demande d'impression a échouée", "/assets/svg/print-outline.svg");
  //       break;
  //   }
  // }

  share() {
    this.showNotification("<strong>Téléchargement effectué</strong>", "/assets/svg/download.svg");
  }

  email() {
    var regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var input = document.querySelector<HTMLInputElement>('input[name=email]').value;

    if (input != "" && regularExpression.test(String(input).toLowerCase())) {
      let emailDatas = new Email(this.configuration.current_config.event_id, this.getCurrentImage_b64(), input);
      let url = this.configuration.getEmailURL();

      // console.log('URL : ' + url);
      // console.log('DATAS : ' + JSON.stringify(emailDatas));
      // console.log("HEADERS : " + JSON.stringify(this.headers));
      // console.log("PROPERTIES : " + JSON.stringify(Properties.headers));

      this.http.post(url, JSON.stringify(emailDatas), {'headers': this.headers}).subscribe((success) => {
        if(this.configuration.current_config.online){
          this.showNotification("Photo envoyée avec succès", "/assets/svg/email.svg");
        }else{
          this.showNotification("Votre photo sera envoyée à la fin de l'évènement", "/assets/svg/email.svg");
        }
        
        document.querySelector<HTMLElement>('.preview_action').style.display = 'block';
        document.querySelector<HTMLElement>('.preview_email').style.display = 'none';
      }, (error) => {
        console.log(error);
        var alert_message = "Votre envoie d'email a échouée";
        this.show_snackbar(alert_message);
      });
    } else {
      this.show_snackbar("Merci de saisir un email valide");
    }
  }

  async ngOnDestroy() {
    if (this.printStatusSubcription != null) {
      this.printStatusSubcription.unsubscribe();
    }
    this.router.navigate(['/home']);
  }

  private printAuthorisation(terminalId: string, minutesBack: number, nbPrintMax: number) {
    if (minutesBack == 0 || nbPrintMax == 0) {
      return true;
    }
    else {
      let datetimeNow = Date.now();
      let datetimeFilter = datetimeNow - (1000 * 60 * minutesBack);
      let photos: any = JSON.parse(localStorage.getItem('photos' + terminalId));
      if (photos != null) {
        let counter = 0;
        for (let photo of photos) {
          if (photo > datetimeFilter) {
            counter++;
          }
        }
        if (counter < nbPrintMax) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }
}

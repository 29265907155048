import { Component, OnInit } from '@angular/core';
import { PopoverController } from "@ionic/angular";
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {

  contentHtml: any;
  iconPath: any;

  constructor(private popover: PopoverController, public configuration: ConfigurationService) { }

  ngOnInit() {}

  closeNotification() {
    this.popover.dismiss();
  }

}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SQLiteService } from './services/sqlite.service';
import { DatePipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { NotificationComponent } from './notification/notification.component';

import { CapturePageModule } from './capture/capture.module';
import { HomePageModule } from './home/home.module';
import { PreviewPageModule } from './preview/preview.module';
import { ConfigurationService } from './services/configuration.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DeviceImageComponent } from './device-image/device-image.component';

@NgModule({
  declarations: [
    AppComponent,
    NotificationComponent,
    DeviceImageComponent,
  ],
  entryComponents: [],
  imports: [
    ImageCropperModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }), 
    HttpClientModule, 
    HomePageModule, 
    CapturePageModule, 
    PreviewPageModule],
  providers: [SQLiteService, DatePipe, HttpClient, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, ConfigurationService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

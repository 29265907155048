export class Email {
    event_id: string;
    image_base64: string;
    email_dest: string;
    constructor(_event_id: string, _image_base64: string, _email_dest: string) {
        this.event_id = _event_id;
        this.image_base64 = _image_base64;
        this.email_dest = _email_dest;
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { CameraService } from '../services/camera.service';
import { ConfigurationService } from '../services/configuration.service';
import { timer } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd , ResolveStart } from '@angular/router';
import { CameraPreview } from '@capacitor-community/camera-preview';

@Component({
  selector: 'app-capture',
  templateUrl: './capture.page.html',
  styleUrls: ['./capture.page.scss'],
})
export class CapturePage implements OnInit, OnDestroy {

  countdown = null;
  countdown_timeLeft = 3;
  orientation = "";

  cameraPreview;

  constructor(public cameraService: CameraService, public configuration: ConfigurationService, private router: Router) {

    //  Dans le cas où l'orientation du téléphone à changé sur la page preview et que l'on revient sur la page Capture avec le bouton Retour, il faut forcer
    //  le redéclenchement de cropVideo. Pour celà on utilise Router qui a un problème sur ios ce qui nous oblige à tester si on est sur mac ou pas.
    if(this.isMac()) {
      router.events.subscribe(ev => {
          if(ev instanceof NavigationEnd) {
            setTimeout(() => {
              var mql = window.matchMedia("(orientation: portrait)");
              if(mql.matches) {  
                if(this.orientation != "portrait"){
                  this.cropVideo();
                }
                this.orientation = "portrait";
              }else{
                if(this.orientation != "paysage"){
                  this.cropVideo();
                }
                this.orientation = "paysage";
              }
            }, 300);
          }
      })
    }else{
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd ) {
          if(screen.orientation.type != this.orientation){
            this.cropVideo();
            this.orientation = screen.orientation.type;
          }
        }    
      });
    }
  }

  isMac() {
    if(navigator.userAgent.indexOf('Mac') > -1) {
        return true;
    }
    return false;
  }

  async launchCountDown() {
    const source = timer(0, 1000);
    const abc = source.subscribe(val => {
      this.countdown = this.countdown_timeLeft - val;
      if (this.countdown == 0) {
        abc.unsubscribe();
        // On affiche plus le timer
        this.countdown = null;
        let imagePromise = this.cameraService.takePicture();
        imagePromise.then((image) => {
          this.cameraService.currentImage = image.value;
          window.removeEventListener("orientationchange", this.cropVideo);
          // Redirection vers la page de preview
          this.ngOnDestroy();
          this.router.navigate(['/preview']);
        });
      }
    });
  }

  flipCamera() {
    this.cameraService.flipCamera();
    const promise = this.cameraService.startCamera();
    promise.then(()=>{
      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if(isSafari){
        // this.waitCameraPreview();
        // this.cropVerificator(0);
        this.waitForElm('#cardPreview').then((elm) => {
          this.cropVideo();
        })
      }else{
        this.cropVideo();
      }
    });
  }

  ngOnInit() {
    // We launch configuration in case of page is refresh
    this.configuration.launchConfigurationProcess(null);
  }

  ionViewWillEnter(){
    // this.waitForElm('#cardPreview').then((elm) => {
    //   console.log('Element is ready', elm);
    // });
    // this.waitCameraPreview();
    const promise = this.cameraService.startCamera();
    promise.then(()=>{
      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if(isSafari){
        this.waitForElm('#cardPreview').then((elm) => {
            this.cropVideo();
          
            var portrait = window.matchMedia("(orientation: portrait)");
            this.cameraPreview = document.getElementById('cameraPreview');
            if(portrait.matches) { // Orientation : Portrait 
              if(this.configuration.current_config.landscape){ // Config : Paysage
                this.cameraPreview.style.height = "calc(80vw * 2 / 3)";
                this.cameraPreview.style.width = "80vw";

              }else{ // Config : Portrait
                let newHeight = document.getElementById('cardPreview').clientWidth * 3/2;
                this.cameraPreview.style.height = newHeight.toString() + 'px';
              }
            }else{// Orientation : Paysage
              if(this.configuration.current_config.landscape){ // Config : Paysage
                this.cameraPreview.style.height = "calc(80vw * 2 / 3)";
                this.cameraPreview.style.width = "80vw";
              }else{// Config : Portrait
                this.cameraPreview.style.height = "70vh";
                this.cameraPreview.style.width = "calc(70vh * 2 / 3)";
              }
            }

        });
        window.addEventListener("orientationchange", this.cropVideo);
      }
      
      // this.waitCameraPreview();
      // let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      // if(isSafari){
      //   let intervalCardPreview = window.setInterval(()=>{
      //     if( document.getElementById('cardPreview') != null){
      //       clearInterval(intervalCardPreview);
      //       let intervalCardPreviewResize = window.setInterval(()=>{
      //         if( document.getElementById('cardPreview') != null){
      //           clearInterval(intervalCardPreviewResize);

      //           var portrait = window.matchMedia("(orientation: portrait)");
      //           let cameraPreview = document.getElementById('cameraPreview');
      //           if(portrait.matches) { // Orientation : Portrait 
      //             if(this.configuration.current_config.landscape){ // Config : Paysage
      //               cameraPreview.style.height = "calc(80vw * 2 / 3)";
      //               cameraPreview.style.width = "80vw";

      //             }else{ // Config : Portrait
      //               let newHeight = document.getElementById('cardPreview').clientWidth * 3/2;
      //               cameraPreview.style.height = newHeight.toString() + 'px';
      //             }
      //           }else{// Orientation : Paysage
      //             if(this.configuration.current_config.landscape){ // Config : Paysage
      //               cameraPreview.style.height = "calc(80vw * 2 / 3)";
      //               cameraPreview.style.width = "80vw";
      //             }else{// Config : Portrait
      //               cameraPreview.style.height = "70vh";
      //               cameraPreview.style.width = "calc(70vh * 2 / 3)";
      //             }
      //           }
      //         }
      //       }, 200);
            
      //       window.addEventListener("orientationchange", this.cropVideo);
      //     }
      //   }, 200);
      // }
      else{
        this.cropVideo();
        screen.orientation.addEventListener("change", ()=>{
          this.cropVideo();
        });
      }
    });
    // this.cropVerificator(0);
  }

  // waitCameraPreview(){
  //   //Prevent Error: Le startCamera() est fait trop tot : cameraPreview n'existe pas encore
  //   if(document.getElementById("cameraPreview") == null) {
  //       setTimeout(() => {this.waitCameraPreview()}, 200);
  //   }else{
  //     const promise = this.cameraService.startCamera();
  //     promise.then(this.cropVideo);
  //   }
  // }
  
  waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}


  async ngOnDestroy() {
    await this.cameraService.stopCamera();
  }

  cropVideo(){
    // this.waitForElm('#video').then((elm) => {
    let intervalId = window.setInterval(function(){
      if( document.getElementById('video')!=null){
        clearInterval(intervalId);
        // this.cameraPreview = document.getElementById('cameraPreview');

        let video = this.cameraPreview.childNodes[4];
        let videoWidth = video.offsetWidth;
        let videoHeight = video.offsetHeight;
        let mask = this.cameraPreview.childNodes[1];
        let maskWidth = mask.offsetWidth;
        let maskHeight = mask.offsetHeight;
        
        let clipHorizontale = 0;
        let clipVertical = 0;
        if(maskWidth<maskHeight){
          clipHorizontale = Math.abs((videoWidth - maskWidth))/2;
        }else{
          clipVertical = Math.abs((videoHeight - maskHeight));
        }
        video.style.clipPath = "inset(0px "+ clipHorizontale+ "px " +clipVertical+ "px " +clipHorizontale+ "px round 10px)";

        this.cameraPreview.childNodes[0].style.cssText += "width:"+maskWidth+"px;";
      }
    }, 400 );
    // );
  }

  // cropVerificator(index:any){
  //   this.cropVideo();
  //   setTimeout(() => {
  //     if(index<3){
  //       this.cropVerificator(index+1);
  //     }
  //   }, 1000);
  // }

  
}

import { Hotspot} from './hotspot.model';

export class EventConfig {
    event_id: string;
    event_name: string;
    event_titre: string;
    event_description: string;
    event_logo_b64: string;
    event_mask_b64: string;
    hotspots: Array<Hotspot>;
    online: boolean;
    print_message: string;
    print_time_limit: number;
    print_number_limit: number;
    event_background_img_b64: string;
    event_color: string;
    allow_upload_image: boolean;
    allow_download: boolean;
    allow_email: boolean;
    email_content: string;
    landscape: boolean;
    constructor(_event_id: string, _event_name: string, _event_description: string, _event_logo_b64: string, _event_mask_b64: string, 
                _hotspots: Array<Hotspot>, _online: boolean, _print_message: string, print_time_limit: number, print_number_limit: number, 
                event_background_img_b64: string, event_titre: string, event_color: string, allow_upload_image:boolean, allow_download:boolean,
                allow_email: boolean, email_content: string, landscape: boolean) {
      this.event_id = _event_id;
      this.event_name = _event_name;
      this.event_description = _event_description;
      this.event_logo_b64 = _event_logo_b64;
      this.event_mask_b64 = _event_mask_b64;
      this.hotspots = _hotspots;
      this.online = _online;
      this.print_message = _print_message;
      this.print_time_limit = print_time_limit;
      this.print_number_limit = print_number_limit;
      this.event_background_img_b64 = event_background_img_b64;
      this.event_titre = event_titre;
      this.event_color = event_color;
      this.allow_upload_image = allow_upload_image;
      this.allow_download = allow_download;
      this.allow_email = allow_email;
      this.email_content = email_content;
      this.landscape = landscape;
    }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { CameraService } from '../services/camera.service';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-device-image',
  templateUrl: './device-image.component.html',
  styleUrls: ['./device-image.component.scss'],
})
export class DeviceImageComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  ratio: number;
  constructor(public cameraService: CameraService, private router: Router, public configuration: ConfigurationService, private location: Location){
    if(this.configuration.current_config.landscape){
      this.ratio = 3 / 2;
    }else{
      this.ratio = 2 / 3;
    }
  }

  ngOnInit(){
    let element: HTMLElement = document.querySelector('#selectFile') as HTMLElement;
    element.click();
  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      
  }
  imageLoaded(image: LoadedImage) {
    document.getElementById('selectFileContainer').setAttribute("style", 'display:none;');
      // show cropper
  }
  cropperReady() {
      // cropper ready
      
  }
  validateCrop(){
    this.cameraService.currentImage = this.croppedImage.slice(22);
    this.router.navigate(['/preview']);
  }

  loadImageFailed() {
      // show message
    // document.getElementById('selectFile').setAttribute("style", 'display:block;');
  }

  inputClick(){
    let element: HTMLElement = document.querySelector('#selectFile') as HTMLElement;
    element.click()
  }

}

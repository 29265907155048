export class MediaForHotspot {
    image: string;
    media_uid: string;
    event_id: string;
    media_type: string;
    constructor(_image: string, _media_uid: string, _event_id: string, _media_type: string) {
        this.image = _image;
        this.media_uid = _media_uid;
        this.event_id = _event_id;
        this.media_type = _media_type;
    }
}

export class Hotspot {
    name: string;
    domaine: string;
    localisation: string;
    wifi_name: string;
    constructor(_name: string, _domaine: string, _localisation: string, _wifi_name: string) {
      this.name = _name;
      this.domaine = _domaine;
      this.localisation = _localisation;
      this.wifi_name = _wifi_name;
    }
}

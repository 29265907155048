import { Injectable, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Hotspot } from '../hotspot.model';
import { EventConfig } from '../event-config.model';
import Properties from '../../assets/properties.json';
import { Device } from '@capacitor/device';

//Protocole: HTTPS needed : PWA need HTTPS, and Chrome restriction HTTP connection inside PWA

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService implements OnDestroy {

  current_connected_hotspot: Hotspot;
  current_config: EventConfig;
  headers: HttpHeaders;

  eventConfigSubscription: Subscription;
  eventConfigSubscription_quick: Subscription;
  checkHotspotSubscription: Subscription;

  linkcgvEvent = "#";

  terminal_id: string;

  private event_uuid: string = null;
  private uuid_service: string = null;

  count_attempt_hotspot_connection = 0;

  constructor(private http: HttpClient) {
    //Define HTTP Header with CORS possibilities
    this.headers = new HttpHeaders();
    Properties.headers.forEach(element => {
      this.headers = this.headers.set(element['key'], element['value']);
    });
    this.loadTerminalId();
    this.uuid_service = (Math.random() +1).toString(36).substring(7);
  }
  
  private async loadTerminalId() {
    this.terminal_id = await Device.getId().then((resp) => resp.uuid);
  }

  launchConfigurationProcess(event_uuid: string){
    if(event_uuid){
      this.event_uuid = event_uuid;
      localStorage.setItem('uuid', event_uuid);
    }else{
      this.event_uuid = localStorage.getItem('uuid');
    }
    // Retrieve event config process (borne non atteignable)
    this.retrieveEventConfig(true);

    this.startEventConfigSubscriptionQuick();

    if (!this.event_uuid) {
      // Retrieve hotspot status process
      this.checkHotspotSubscription = interval(5000).subscribe(() => this.checkHotSpotConnection());
    }
  }

  // Ce timer permet de lancer le process de récupération rapidement (toutes les 3s). 
  //Dès qu'une configuration est récupérée, ce timer est stoppé pour laisser la place au eventConfigSubscription (toutes les 60s)
  async startEventConfigSubscriptionQuick() {
    this.eventConfigSubscription_quick = interval(3000).subscribe(() => this.retrieveEventConfig(false));
  }

  async startEventConfigSubscription() {
    this.eventConfigSubscription_quick.unsubscribe();
    this.eventConfigSubscription = interval(60000).subscribe(() => this.retrieveEventConfig(false));
    console.log('Uuid service Configuration : ' + this.uuid_service);
  }

  async checkHotSpotConnection() {
    if (this.current_config != null) {
      for (let i = 0; i < this.current_config.hotspots.length; i++) {
        let current_hotspot = this.current_config.hotspots[i];
        const hotspotURL = Properties.protocole + current_hotspot.domaine + ':' + Properties.hotspots_port + '/config/';
        await this.getConfig(hotspotURL, current_hotspot.domaine);
      }
    }
  }

  // Récupération de la configuration :
  async retrieveEventConfig(firstConnection:boolean) {
    // Dans le cas d'une 1ère connection, on essaie de se connecter en offline puis en online
    if(firstConnection){
      // Retrieve config from local HotSpots
      console.log("Connexion au hotspot");
      for (let i = 0; i < Properties.hotspots_potentials.length; i++) {
        let current_domaine = Properties.hotspots_potentials[i];
        const hotspotURL = Properties.protocole + current_domaine + ':' + Properties.hotspots_port + '/config/';
        await this.getConfig(hotspotURL, current_domaine);
      }
      if(this.current_config != null){
        if(!this.current_config.online){
          localStorage.removeItem('uuid');
        }
      }else if(this.event_uuid){
        console.log("Connexion au server");
        const adminServerURL = Properties.protocole + Properties.admin_server + '/configs/' + this.event_uuid;
        // const adminServerURL = "http://printfieadmin/configs/a7654c52-8795-4d29-99e6-8779ffd2e224";

        await this.getConfig(adminServerURL, '');
      }
    }else{
      if(this.event_uuid){
        // this.event_uuid = localStorage.getItem('uuid');
        console.log("Connexion au server");
        // Retrieve config from internet (admin server)
        //const adminServerURL = "http://127.0.0.1:8000/configs/a7654c52-8795-4d29-99e6-8779ffd2e224";
        //const adminServerURL = Properties.protocole + Properties.admin_server + '/configs/' + localStorage.getItem('event_id');
        const adminServerURL = Properties.protocole + Properties.admin_server + '/configs/' + this.event_uuid;
        await this.getConfig(adminServerURL, '');
      }
    }
     
    if (this.current_config != null && !this.eventConfigSubscription_quick.closed) {
      this.startEventConfigSubscription();
    }
  }

  private async getConfig(hotspot_URL: string, hotspot_domaine: string) {
    //console.log('check config ' + hotspot_URL + ' - ' + hotspot_domaine + ' - ' + new Date() + ' - ' + this.current_connected_hotspot);
    this.http.get(hotspot_URL, { 'headers': this.headers.append('timeout', '2') }).subscribe((success) => {
      const body = success;
      const event_id = body['id'];
      // If current connected hotspot is null or event id are different
      if (this.current_connected_hotspot == null || (this.current_config && this.current_config.event_id != event_id)) {
        // Extract datas
        const event_name = body['name'];
        console.log('Retrieve config : ' + event_name);
        const event_description = body['description'];
        const event_logo_b64 = body['logo_b64'];
        const event_mask_b64 = body['mask_b64'];
        const hotspots = body['hotspots'];
        const online = body['online'];
        const print_message = body['print_message'];
        const print_time_limit = body['print_time_limit'];
        const print_number_limit = body['print_nb_limit'];
        const background_img = body['background_b64'];
        const event_titre = body['title'];
        const allow_upload_image = body['allow_upload_image'];
        const allow_download = body['allow_download'];
        const allow_email = body['allow_email'];
        const email_content = body['email_content'];
        const event_color = body['color'];
        const hotspotArray: Array<Hotspot> = [];
        if (hotspots) {
          hotspots.forEach(element => {
            const hs = new Hotspot(element['name'], element['domaine'], element['localisation'], element['wifi_name']);
            hotspotArray.push(hs);
            // Set current connected hotspot
            if (!online && hotspot_domaine == hs.domaine) {
              this.current_connected_hotspot = hs;
            }else{
            }
          });
        } else {
          console.log('Cannot set current hotspot: no hotspot');
        }
        // Store event configuration
        // Test si Paysage ou portrait
        var mask = new Image();
        mask.src = 'data:image/jpeg;base64,' + event_mask_b64;
        mask.onload = () => {
          var landscape = false;
          if(mask.width>mask.height){
            landscape = true;
          }
          this.current_config = new EventConfig(event_id, event_name, event_description, event_logo_b64, event_mask_b64, hotspotArray, online, print_message, print_time_limit, print_number_limit, background_img, event_titre, event_color, allow_upload_image, allow_download, allow_email, email_content, landscape);
        }
      } else {
        // It's the same hotspot
      }
    }, (error) => {
      if (this.current_connected_hotspot != null && this.current_connected_hotspot.domaine == hotspot_domaine && this.count_attempt_hotspot_connection > 5) {
        this.current_connected_hotspot = null;
        console.log('Current hotspot ' + hotspot_URL + " unreachable");
        this.count_attempt_hotspot_connection = 0;
      }
      this.count_attempt_hotspot_connection++;
    });
  }

  getCurrentConfig() {
    return this.current_config;
  }

  getCurrentConnectedHotSpot() {
    return this.current_connected_hotspot;
  }

  getHostpostPrintingURL() {
    if (this.current_config.online) {
      return Properties.protocole + Properties.admin_server + '/medias/upload';
    } else {
      return Properties.protocole + this.current_connected_hotspot.domaine + ':' + Properties.hotspots_port + '/print/';
    }
  }

  getEmailURL() {
    if (this.current_config.online) {
      return Properties.protocole + Properties.admin_server + '/email/send';
    } else {
      return Properties.protocole + this.current_connected_hotspot.domaine + ':' + Properties.hotspots_port + '/email/send';
    }
  }

  ngOnDestroy() {
    if (this.eventConfigSubscription != null) {
      this.eventConfigSubscription.unsubscribe();
    }
    if (this.eventConfigSubscription_quick != null) {
      this.eventConfigSubscription_quick.unsubscribe();
    }
    if (this.checkHotspotSubscription != null) {
      this.checkHotspotSubscription.unsubscribe();
    }
  }
}
